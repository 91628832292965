<template>
  <div>
    <v-menu v-model="shower" :position-x="AreaX" :position-y="AreaY" close-on-click close-on-content-click>
      <v-list dense tile>
        <v-list-item v-for="(item, index) in ItemOption" :key="index" @click="fn_selectFunction(item.select_funtion)">
          <v-list-item-icon>
            <v-icon medium v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item @click="fn_copyfile()">
          <v-list-item-icon>
            <v-icon v-text="Itemcopyfile[0].icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t(Itemcopyfile[0].text) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <!-- ไม่ใช้วาง -->
        <!-- <v-list-item @click="fn_pastefile()" v-if="checkbtncopy == true">
          <v-list-item-icon>
            <v-icon v-text="Itemcopyfile[1].icon"> </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t(Itemcopyfile[1].text) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
      </v-list>
    </v-menu>
    <multi_movefile
      :show="opendialog"
      :filemultipledownload="filemultipledownload"
      :parentfolder="parentfolder"
      @closedialog="opendialog = false"
      @reload="fn_parentreload()"
      @closeappsbar="$emit('closeappsbar')"
    ></multi_movefile>
    <dialogsnackbarmultidownload
      :show="opensnackbarmultidownload"
      @closedialog="opensnackbarmultidownload = false"
      :percent="percent"
      :date="datetime"
      :btsdownload="btsdownload"
    ></dialogsnackbarmultidownload>
    <dialogcheckmultidownload
      :show="opendialogcheckmultidownload"
      @closedialog="opendialogcheckmultidownload = false"
      :data="filemultipledownload"
    ></dialogcheckmultidownload>
    <dialogeditdeletehashtag
      :show="opendialogeditdeletehashtag"
      :multihashtag="filemultipledownload"
      @close="opendialogeditdeletehashtag = false"
      @reload="$emit('reload')"
      :filedata="{}"
      :listcheckhashtag="{}"
    ></dialogeditdeletehashtag>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import gbfdecodearraybuffer from "@/globalFunctions/decodearraybuffer";
import axios from "axios";
import dialogeditdeletehashtag from "../optional/dialog-editdeletehashtag";
const multi_movefile = () => import("../optional/dialog-multimovefile");
const dialogsnackbarmultidownload = () => import("../optional/dialog-snackbarmultidowmload");
const dialogcheckmultidownload = () => import("../optional/dialog-checkmultidownload");
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  data: function() {
    return {
      checkbtncopy: false,
      listfilecopy: [],
      listcopyfile: [],
      btsdownload: false,
      opendialog: false,
      opendialogcheckmultidownload: false,
      percent: 0,
      opensnackbarmultidownload: false,
      opendialogeditdeletehashtag: false,
      datetime: "",
      check_delete: 0,
      ItemOption: [
        {
          text: "multirightclick.download",
          select_funtion: "download",
          icon: "mdi-download",
        },
        {
          text: "multirightclick.delete",
          select_funtion: "delete",
          icon: "delete",
        },
        {
          text: "multirightclick.star",
          select_funtion: "star",
          icon: "star",
        },
        {
          text: "multirightclick.movefiles",
          select_funtion: "movefile",
          icon: "mdi-folder-swap",
        },
        {
          text: "multirightclick.hashtag",
          select_funtion: "hashtag",
          icon: "mdi-pencil",
        },
      ],
      Itemcopyfile: [
        {
          text: "คัดลอกที่เลือก",
          select_funtion: "copyfile",
          icon: "file_copy",
        },
        {
          text: "วาง",
          select_funtion: "paste",
          icon: "mdi-file-replace",
        },
      ],
    };
  },
  props: ["show", "AreaX", "AreaY", "filemultipledownload", "parentfolder", "file","status_data"],
  components: {
    multi_movefile,
    dialogsnackbarmultidownload,
    dialogcheckmultidownload,
    dialogeditdeletehashtag,
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "role_level"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.filemultipledownload.length <= 1) {
          this.$emit("closecurrentonly");
        }
        let get_sessionfilecopy = [];
        get_sessionfilecopy = sessionStorage.getItem("filecopy");
        // console.log("get_sessionfilecopy",get_sessionfilecopy);
        if (get_sessionfilecopy === "" || get_sessionfilecopy === null || get_sessionfilecopy === undefined) {
          this.checkbtncopy = false;
        } else {
          this.checkbtncopy = true;
        }
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit("closecurrentonly");
        }
      },
    },
  },
  methods: {
    //copyfile
    fn_copyfile() {
      this.listcopyfile = [];
      console.log("this.filemultipledownload", this.filemultipledownload);
      for (let i = 0; i < this.filemultipledownload.length; i++) {
        if (
          this.filemultipledownload[i].file_type !== "folder" &&
          this.filemultipledownload[i].file_permission_2 !== "01"
        ) {
          this.listcopyfile.push(this.filemultipledownload[i]);
        }
      }
      sessionStorage.setItem("filecopy", JSON.stringify(this.listcopyfile));
      Toast.fire({
        icon: "success",
        title: "คัดลอกไฟล์ที่เลือก",
      });
    },
    //วางไฟล์
    async fn_pastefile() {
      if (this.$router.currentRoute.path === "/mydrive") {
      } else {
        this.this.listfilecopy = [];
        let data_copy = sessionStorage.getItem("filecopy");
        let data_paste = JSON.parse(data_copy);
        console.log("data_paste", data_paste);

        for (let i = 0; i < data_paste.length; i++) {
          this.listfilecopy.push({
            data_type: data_paste[i].file_type === "folder" ? "folder" : "file",
            data_id: data_paste[i].file_id,
          });
        }
        console.log("listfilecopy", this.listfilecopy);
        // if(currentfile.file_type === 'folder'){}
        let payload = {
          account_id: this.dataAccountId,
          folder_id: this.$route.params.id,
          copied: this.listfilecopy,
        };
        console.log("payloadcopyfile", payload);
        let auth = await gbfGenerate.generateToken();
        console.log("auth", auth);
        let resp = await axios.post(process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/paste_data", payload, {
          headers: { Authorization: auth.code },
        });
        console.log(resp);
        try {
          if (resp.data.status === "OK") {
            // this.createprogress = false;
            Toast.fire({
              icon: "success",
              title: "วาง",
            });
            this.$emit("reload");
          } else {
            // this.createprogress = false;
            Toast.fire({
              icon: "error",
              title: resp.data.errorMessage,
            });
          }
        } catch (err) {
          console.log(err);
          Toast.fire({
            icon: "error",
            title: err,
          });
        }
      }
    },
    fn_selectFunction(path) {
      if (path == "download") {
        // this.opendialogcheckmultidownload = true;
        this.opensnackbarmultidownload = true;
        this.fn_multipledownload();
        // this.multipledownload();
      } else if (path == "delete") {
        this.fn_multidelete();
      } else if (path == "movefile") {
        this.opendialog = true;
      } else if (path == "hashtag") {
        this.opendialogeditdeletehashtag = true;
      } else {
        this.fn_setmultiupdate();
      }
    },
    // function download pack
    fn_clearmultipledownload() {
      this.filemultipledownload.splice(0, this.filemultipledownload.length);
    },
    fn_zeroPadding(num, digit) {
      var zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    },

    //ไม่ได้ใช้เปลี่ยยนเป็นส่งค่าไปใน dialog
    //ไปใช้ใน dialog checkmultidownload
    // function download pack
    async fn_multipledownload() {
      this.btsdownload = true;
      console.log("muti download rightclick", this.filemultipledownload);
      this.percentCompleted = 0;
      this.percent = 0;
      var d = new Date();
      this.datetime =
        d.toISOString().slice(0, 10) +
        " " +
        "at" +
        " " +
        this.fn_zeroPadding(d.getHours(), 2) +
        "-" +
        this.fn_zeroPadding(d.getMinutes(), 2) +
        "-" +
        this.fn_zeroPadding(d.getSeconds(), 2);

      // console.log("this.datetime",this.datetime);
      if (this.filemultipledownload.length < 1) {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      } else {
        let filedata = [];
        for (let i = 0; i < this.filemultipledownload.length; i++) {
          // push type
          if (this.filemultipledownload[i]["file_type"] === "folder") {
            filedata.push({
              id: this.filemultipledownload[i]["file_id"],
              type: this.filemultipledownload[i]["file_type"],
            });
          } else {
            filedata.push({
              id: this.filemultipledownload[i]["file_id"],
              type: "file",
            });
          }
        }
        let payload = {
          list: filedata,
          account_id: this.dataAccountId,
        };
        console.log("payloads", payload);
        // this.snackbardowloadfile = true;
        this.opensnackbarmultidownload = true;
        let auth = await gbfGenerate.generateToken();

        this.axios
          .post(process.env.VUE_APP_SERVICE_DOWNLOAD_MULTIPLE + "/api/download_multiples_all", payload, {
            onDownloadProgress: (progressEvent) => {
              // console.log(progressEvent);
              let progresspercent = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
              // console.log(progresspercent);
              if (progresspercent >= 95) {
                this.percentCompleted = 100;
              } else {
                this.percentCompleted = progresspercent;
              }
              this.percent = this.percentCompleted;
              // console.log("this.percent",this.percent);
            },
            withCredentials: false,
            responseType: "arraybuffer",
            headers: { Authorization: auth.code },
          })
          .then((response) => {
            console.log(response);
            // if (response.statusText === "OK") {
            if (response.status === 200) {
              if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
                this.opensnackbarmultidownload = false;
                let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
                Toast.fire({ icon: "error", title: res_error.errorMessage });
                } else {
                  this.percentCompleted = 100;
                  this.btsdownload = false;

                  let headers = response.headers;
                  let blob = new Blob([response.data],{type:headers['content-type']});
                  let link = document.createElement("a");
                  link.href = window.URL.createObjectURL(blob);
                  link.download = this.datetime;
                  link.click();

                  // const blob = new Blob([response.data]);
                  // const content = response.headers["content-type"];
                  // saveAs(blob, this.datetime + ".zip");

                  setTimeout(() => {
                    this.opensnackbarmultidownload = false;
                  }, 2500);
                }
              
            }
          
            // this.clearmultipledownload();
          })
          .catch((error) => {
            this.btsdownload = false;
            this.$store.dispatch("show_loading", false);
            Toast.fire({
              icon: "error",
              title: this.$t("myinboxPage.msg.apierror"),
            });
            console.log(error);
          });
        this.$emit("closecurrentonly");
        this.$emit("clearparentvalue");
      }
    },

    // function delete pack
    // ยังไม่มีลบ แบบ TB
    async multideletetotrash() {
      // this.$store.dispatch("set_loading", true).then((res) => {});
      console.log("mul del");
      console.log("filemultipledownload", this.filemultipledownload);
      let datalist = [];
      let status_data = "T";
      if (this.dataAccountActive.type == "Business") {
        this.$emit("multideleteforbusinsess");
      } else {
        status_data = "T";
        for (let i = 0; i < this.filemultipledownload.length; i++) {
          console.log(this.filemultipledownload[i]);
          if (this.filemultipledownload[i].file_type === "folder") {
            console.log("folder");
            datalist.push({
              data_id: this.filemultipledownload[i]["file_id"],
              data_type: this.filemultipledownload[i]["file_type"],
              data_status: status_data,
            });
          } else {
            console.log("file");
            datalist.push({
              data_id: this.filemultipledownload[i]["file_id"],
              data_type: "file",
              data_status: status_data,
            });
          }
          // เช็คถ้าไม่ใช่ไฟล์ของฉันหรือเป็นแอดมินไม่ให้ลบ
          // if(this.filemultipledownload[i].file_owner_eng !== "Me" || this.role_level !== "True"){
          //   datalist.pop()
          // }
        }
        // console.log("Test check file and role", datalist);
        let payload = {
          account_id: this.dataAccountId,
          user_id: this.dataUsername,
          data_list: datalist,
          // status_data:"T"
        };
        // console.log("payload multi delete right click", payload);
        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + "/api/remove_multiples_file_folder_to_trash", payload, {
            headers: { Authorization: auth.code },
          })
          .then((response) => {
            console.log(response);
            if (response.data.status === "OK") {
              this.$store.dispatch("set_loading", false).then((res) => {});
              Toast.fire({
                icon: "success",
                title: this.$t("toast.multiset.remove"),
              });
              // this.statusmutipledownload = false;
              // this.clearmultipledownload();
              // this.loadfolder();
              this.$emit("reload");
              this.$emit("clearparentvalue");
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          });
      }
    },

    // วนลูปเอาไฟล์เข้าถังขยะ
    async fn_multidelete() {
      for (let i = 0; i < this.filemultipledownload.length; i++) {
        let payload = {
          account_id: this.dataAccountId,
          user_id: this.dataUsername,
          data_id: this.filemultipledownload[i]["file_id"],
          data_type: this.filemultipledownload[i].file_type === "folder" ? "folder" : "file",
          status_data: this.status_data,
        };
        await this.fn_delete(payload)
      }
      Toast.fire({
        icon: "success",
        title: this.$t("toast.text.multiremovetotrash")
      });
      this.$emit("reload");
      this.$emit("clearparentvalue");
      console.log("enddelete");
    },
    // เอาไฟล์ลงถังขยะทีละไฟล์
    async fn_delete(payload) {
      let auth = await gbfGenerate.generateToken();
      let result = await this.axios({
        method: "POST",
        url: process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + "/api/remove_file_folder_to_trash",
        data: payload,
        headers: { Authorization: auth.code }
      });
      try {
        if (result.data.status === "OK") {
          console.log("delete success");
        } else {
          console.log(result.data.errorMessage);
        }
      } catch (err) {
        Toast.fire({
          icon: "error",
          title: err,
        });
      }
    },


    // function star file
    async fn_setmultiupdate() {
      // console.log('aaaa',this.filemultipledownload,status);
      if (this.filemultipledownload.length < 1) {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      } else {
        let apifile;
        let apifolder;
        let payloadfile;
        let payloadfolder;
        let msgres;
        let filedata = [];
        let folderdata = [];
        let i;
        let status = "S";
        console.log("jjjfjjdijeiirr", this.filemultipledownload, status);
        for (i = 0; i < this.filemultipledownload.length; i++) {
          if (this.filemultipledownload[i].file_type === "folder") {
            folderdata.push(this.filemultipledownload[i]["file_id"]);
            payloadfolder = {
              list_folder: folderdata,
              account_id: this.dataAccountId,
              status_folder: status,
            };
            apifolder = "/api/multiple_update_status_folders";
          } else {
            filedata.push(this.filemultipledownload[i]["file_id"]);
            payloadfile = {
              list_file: filedata,
              account_id: this.dataAccountId,
              status_file: status,
            };
            apifile = "/api/multiple_update_status_files";
          }
        }
        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + apifile, payloadfile, { headers: { Authorization: auth.code } })
          .then((response) => {
            if (response.data.status === "OK") {
              if (status === "S") {
                msgres = "ติดดาวสำเร็จ";
              } else if (status === "T") {
                msgres = "นำออกสำเร็จ";
              } else {
                msgres = "ยกเลิกติดดาวสำเร็จ";
              }
              Toast.fire({
                icon: "success",
                title: msgres,
              });
              // this.clearmultipledownload();
              this.$emit("reload");
              this.$emit("clearparentvalue");
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          });
        this.axios
          .post(process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + apifolder, payloadfolder, {
            headers: { Authorization: auth.code },
          })
          .then((response) => {
            if (response.data.status === "OK") {
              if (status === "S") {
                msgres = "ติดดาวสำเร็จ";
              } else {
                msgres = "ยกเลิกติดดาวสำเร็จ";
              }
              Toast.fire({
                icon: "success",
                title: msgres,
              });
              // this.clearmultipledownload();
              // this.statusmutipledownload = false;
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          });
      }
    },

    // emit
    fn_parentreload() {
      this.$emit("reload");
    },
  },
};
</script>

<style></style>
